import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
});

class AuthService {
  async sendOTP(phoneNumber) {
    try {
      const response = await api.post('/auth/send-otp', { phoneNumber });
      return response.data;
    } catch (error) {
      console.error('OTP Error:', error);
      throw new Error('Failed to send OTP');
    }
  }

  async verifySignup({ phoneNumber, email, password, otp }) {
    try {
      const response = await api.post('/auth/verify-signup', {
        phoneNumber,
        email,
        password,
        otp
      });
      
      if (response.data?.token) {
        localStorage.setItem('auth_token', response.data.token);
      }
      return response.data;
    } catch (error) {
      console.error('Signup Error:', error);
      throw new Error('Signup failed');
    }
  }

  async login({ phoneNumber, password }) {
    try {
      const response = await api.post('/auth/login', {
        phoneNumber,
        password
      });
      
      if (response.data?.token) {
        localStorage.setItem('auth_token', response.data.token);
      }
      return response.data;
    } catch (error) {
      console.error('Login Error:', error);
      throw new Error('Login failed');
    }
  }

  isAuthenticated() {
    return !!localStorage.getItem('auth_token');
  }

  logout() {
    localStorage.removeItem('auth_token');
  }
}

export default new AuthService();