// LoginModal.js
import React, { useState, useRef, useEffect } from 'react';
import { X, Mail, Lock, Phone } from 'lucide-react';
import authService from '../services/authService';
import { toast } from 'react-hot-toast';

export const LoginModal = ({ isOpen, onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const otpInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const phoneInputRef = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, [isOpen]);

  const resetForm = () => {
    setIsLogin(true);
    setPhoneNumber('');
    setEmail('');
    setPassword('');
    setOtp('');
    setShowOTP(false);
    setError('');
    setIsLoading(false);
  };

  if (!isOpen) return null;

  const handlePhoneValidation = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const cleanedPhone = phoneNumber.replace(/\D/g, '').replace(/^91/, '');
      await authService.sendOTP(cleanedPhone);
      toast.success('OTP sent successfully!');
      setShowOTP(true);
      setTimeout(() => otpInputRef.current?.focus(), 100);
    } catch (err) {
      setShowOTP(true);
      setTimeout(() => otpInputRef.current?.focus(), 100);
      toast.success('Please enter the OTP');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const cleanedPhone = phoneNumber.replace(/\D/g, '').replace(/^91/, '');
      await authService.verifySignup({
        phoneNumber: cleanedPhone,
        email,
        password,
        otp
      });
      
      toast.success('Account created successfully!');
      onClose();
      resetForm();
    } catch (err) {
      setError('Signup failed. Please try again.');
      toast.error('Signup failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const cleanedPhone = phoneNumber.replace(/\D/g, '').replace(/^91/, '');
      await authService.login({
        phoneNumber: cleanedPhone,
        password
      });
      
      toast.success('Logged in successfully!');
      onClose();
      resetForm();
    } catch (err) {
      setError('Invalid credentials. Please try again.');
      toast.error('Invalid credentials. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const PhoneInput = ({ disabled = false }) => (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Phone className="h-5 w-5 text-gray-400" />
      </div>
      <div className="absolute inset-y-0 left-10 flex items-center border-r pr-2">
        <span className="text-gray-500 sm:text-sm">+91</span>
      </div>
      <input
        ref={phoneInputRef}
        type="tel"
        maxLength="10"
        disabled={disabled}
        onChange={(e) => {
          const numericValue = e.target.value.replace(/\D/g, '');
          setPhoneNumber(numericValue);
        }}
        className="block w-full pl-24 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-[rgb(252,123,3)] focus:border-[rgb(252,123,3)]"
        placeholder="Enter your phone number"
      />
    </div>
  );

  const SignupForm = () => (
    <form onSubmit={!showOTP ? handlePhoneValidation : handleSignup} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Phone Number
        </label>
        <PhoneInput disabled={showOTP} />
      </div>

      {!showOTP ? (
        <button
          type="submit"
          disabled={phoneNumber.length !== 10 || isLoading}
          className="w-full py-3 px-4 bg-[rgb(252,123,3)] text-white rounded-lg hover:bg-[rgb(232,103,0)] transition-colors disabled:opacity-50 font-medium"
        >
          {isLoading ? 'Sending...' : 'Send OTP'}
        </button>
      ) : (
        <>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Enter OTP
            </label>
            <input
              ref={otpInputRef}
              type="text"
              maxLength="6"
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, '');
                setOtp(numericValue);
                if (numericValue.length === 6) {
                  emailInputRef.current?.focus();
                }
              }}
              className="block w-full px-4 py-2 rounded-lg border focus:ring-2 focus:ring-[rgb(252,123,3)] focus:border-[rgb(252,123,3)]"
              placeholder="Enter the 6-digit OTP"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
              <input
                ref={emailInputRef}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-[rgb(252,123,3)] focus:border-[rgb(252,123,3)]"
                placeholder="Enter your email address"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Password
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-[rgb(252,123,3)] focus:border-[rgb(252,123,3)]"
                placeholder="Create your password"
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={!email || !password || otp.length !== 6 || isLoading}
            className="w-full py-3 px-4 bg-[rgb(252,123,3)] text-white rounded-lg hover:bg-[rgb(232,103,0)] transition-colors disabled:opacity-50 font-medium"
          >
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </button>
          
          <button
            type="button"
            onClick={handlePhoneValidation}
            disabled={isLoading}
            className="w-full text-[rgb(252,123,3)] text-sm hover:underline"
          >
            Resend OTP
          </button>
        </>
      )}
    </form>
  );

  const LoginForm = () => (
    <form onSubmit={handleLogin} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Phone Number
        </label>
        <PhoneInput />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Password
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Lock className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            className="block w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-[rgb(252,123,3)] focus:border-[rgb(252,123,3)]"
            placeholder="Enter your password"
          />
        </div>
      </div>

      <button
        type="submit"
        disabled={phoneNumber.length !== 10 || !password || isLoading}
        className="w-full py-3 px-4 bg-[rgb(252,123,3)] text-white rounded-lg hover:bg-[rgb(232,103,0)] transition-colors disabled:opacity-50 font-medium"
      >
        {isLoading ? 'Logging in...' : 'Login'}
      </button>
    </form>
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl p-8 max-w-md w-full mx-auto relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <X size={24} />
        </button>

        <div className="mb-8 text-center">
          <h2 className="text-2xl font-bold text-gray-900">
            {isLogin ? 'Welcome Back!' : 'Create Account'}
          </h2>
          <p className="text-gray-600 mt-2">
            {isLogin ? 'Login to continue' : 'Sign up to get started'}
          </p>
        </div>

        {error && (
          <div className="p-4 mb-6 bg-red-50 border border-red-100 text-red-600 rounded-lg text-sm">
            {error}
          </div>
        )}

        {isLogin ? <LoginForm /> : <SignupForm />}

        <div className="mt-6 text-center">
          <button
            onClick={() => {
              setIsLogin(!isLogin);
              setShowOTP(false);
              setError('');
              setPhoneNumber('');
              setEmail('');
              setPassword('');
              setOtp('');
            }}
            className="text-[rgb(252,123,3)] hover:underline text-sm font-medium"
          >
            {isLogin ? "Don't have an account? Sign up" : 'Already have an account? Login'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;