import React, { useEffect, useState } from 'react';
import { Globe } from 'lucide-react';

const LanguageSelector = () => {
  const [isTranslateLoaded, setIsTranslateLoaded] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'te', name: 'తెలుగు' },
    { code: 'hi', name: 'हिंदी' },
    { code: 'ml', name: 'മലയാളം' },
    { code: 'kn', name: 'ಕನ್ನಡ' },
    { code: 'ta', name: 'தமிழ்' }
  ];

  useEffect(() => {
    // Only add the script if it hasn't been added yet
    if (!document.getElementById('google-translate-api')) {
      // Create a safe initialization function
      const initTranslate = () => {
        try {
          if (window.google && window.google.translate) {
            new window.google.translate.TranslateElement(
              {
                pageLanguage: 'en',
                includedLanguages: 'te,hi,ml,kn,ta',
                layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                autoDisplay: false,
              },
              'google-translate-element'
            );
            setIsTranslateLoaded(true);
          }
        } catch (error) {
          console.warn('Google Translate initialization failed:', error);
        }
      };

      // Add the initialization function to window
      window.googleTranslateElementInit = initTranslate;

      // Add the script with proper error handling
      const script = document.createElement('script');
      script.id = 'google-translate-api';
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      script.onerror = () => {
        console.warn('Failed to load Google Translate script');
        // Remove the script on error to allow retry
        script.remove();
      };
      
      document.body.appendChild(script);

      // Cleanup
      return () => {
        if (document.getElementById('google-translate-api')) {
          document.getElementById('google-translate-api').remove();
        }
        delete window.googleTranslateElementInit;
      };
    }
  }, []);

  const handleLanguageChange = (languageCode) => {
    try {
      setSelectedLanguage(languageCode);
      
      // If Google Translate is not loaded, store preference and return
      if (!window.google || !window.google.translate) {
        localStorage.setItem('preferredLanguage', languageCode);
        return;
      }

      const currentUrl = window.location.href;
      const isTranslated = currentUrl.includes('translate.google.com');
      
      if (isTranslated) {
        const newUrl = currentUrl.replace(/tl=([^&]*)/, `tl=${languageCode}`);
        window.location.href = newUrl;
      } else if (languageCode !== 'en') {
        const translateUrl = `https://translate.google.com/translate?sl=auto&tl=${languageCode}&u=${encodeURIComponent(currentUrl)}`;
        window.location.href = translateUrl;
      }
    } catch (error) {
      console.warn('Language change failed:', error);
    }
  };

  return (
    <div className="relative">
      {/* Hidden element for Google Translate */}
      <div id="google-translate-element" className="hidden" />
      
      {/* Custom language selector UI */}
      <div className="flex items-center space-x-2">
        <Globe size={20} className="text-gray-500" />
        <select
          value={selectedLanguage}
          onChange={(e) => handleLanguageChange(e.target.value)}
          className="bg-transparent border-none focus:ring-0 cursor-pointer text-lg hover:text-[rgb(252,123,3)] transition-colors appearance-none"
        >
          {languages.map(({ code, name }) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LanguageSelector;