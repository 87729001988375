const config = {
    RAPID_API_KEY: '50387a425fmsh46558ff39450423p113ff4jsn5d52df272a20',  // Temporarily hardcode for testing
    API_URL: "https://wft-geo-db.p.rapidapi.com/v1/geo"
  };
  
  // Validate configuration
  if (!config.RAPID_API_KEY) {
    console.error(
      'API Key is missing. Please check your .env file and ensure REACT_APP_RAPID_API_KEY is set.'
    );
  }
  
  export default config;