// AccountMenu.jsx
import React, { useState } from 'react';
import { User, Calendar } from 'lucide-react';
import { Navigate, useNavigate } from 'react-router-dom'; // Replace React Router import with Next.js router

export const AccountMenu = ({ onLoginClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Use Next.js router instead of useNavigate

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:text-[rgb(252,123,3)] text-lg transform hover:scale-105 transition-all"
      >
        <User size={24} />
        <span>Account</span>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-50">
          <button
            onClick={() => {
              onLoginClick();
              setIsOpen(false);
            }}
            className="w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors"
          >
            Login/Signup
          </button>
          <button
            onClick={() => {
              setIsOpen(false);
              Navigate('/my-bookings'); // Use router.push instead of navigate
            }}
            className="w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors"
          >
            My Bookings
          </button>
        </div>
      )}
    </div>
  );
};