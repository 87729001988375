import React, { useState, useEffect } from 'react';
import { MapPin, Loader } from 'lucide-react';
import { toast } from 'react-hot-toast';

const LocationDetector = ({ onLocationSelect }) => {
  const [currentLocation, setCurrentLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Get location data from IP using multiple fallback services
  const getIPLocation = async () => {
    // Try with ipapi.co first (HTTPS)
    try {
      console.log('Attempting ipapi.co...');
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      console.log('ipapi.co response:', data);
      
      if (!data.error) {
        return {
          city: data.city,
          state: data.region,
          country: data.country_name,
          latitude: data.latitude,
          longitude: data.longitude,
          postal: data.postal,
          ip: data.ip
        };
      }
    } catch (error) {
      console.log('ipapi.co failed:', error);
    }

    // Fallback to ipwho.is (HTTPS)
    try {
      console.log('Attempting ipwho.is...');
      const response = await fetch('https://ipwho.is/');
      const data = await response.json();
      console.log('ipwho.is response:', data);
      
      if (data.success) {
        return {
          city: data.city,
          state: data.region,
          country: data.country,
          latitude: data.latitude,
          longitude: data.longitude,
          postal: data.postal,
          ip: data.ip
        };
      }
    } catch (error) {
      console.log('ipwho.is failed:', error);
    }

    // Final fallback to ipapi.com (HTTPS)
    try {
      console.log('Attempting api.ipapi.com...');
      const response = await fetch('https://api.ipapi.com/api/check?access_key=YOUR_ACCESS_KEY');
      const data = await response.json();
      console.log('api.ipapi.com response:', data);
      
      if (!data.error) {
        return {
          city: data.city,
          state: data.region_name,
          country: data.country_name,
          latitude: data.latitude,
          longitude: data.longitude,
          postal: data.zip,
          ip: data.ip
        };
      }
    } catch (error) {
      console.log('api.ipapi.com failed:', error);
    }

    throw new Error('All location services failed');
  };

  const getBrowserLocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation not supported'));
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Browser geolocation success:', position);
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          console.log('Browser geolocation error:', error);
          reject(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    });
  };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      console.log('Attempting reverse geocoding...');
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`
      );
      const data = await response.json();
      console.log('Reverse geocoding response:', data);
      
      return {
        city: data.address.city || data.address.town || data.address.village,
        state: data.address.state,
        country: data.address.country,
        district: data.address.suburb || data.address.district,
        area: data.address.neighbourhood || data.address.suburb,
        postal: data.address.postcode
      };
    } catch (error) {
      console.log('Reverse geocoding failed:', error);
      throw error;
    }
  };

  const getLocation = async () => {
    setLoading(true);
    setError(null);

    try {
      // Try browser geolocation first
      try {
        const browserLocation = await getBrowserLocation();
        const geocodedLocation = await reverseGeocode(
          browserLocation.latitude,
          browserLocation.longitude
        );
        
        const formattedAddress = formatAddress(geocodedLocation);
        setCurrentLocation(formattedAddress);
        onLocationSelect({
          ...geocodedLocation,
          formatted: formattedAddress,
          latitude: browserLocation.latitude,
          longitude: browserLocation.longitude
        });
        
        toast.success('Location detected successfully');
        return;
      } catch (error) {
        console.log('Browser location failed, falling back to IP:', error);
      }

      // Fallback to IP geolocation
      const ipLocation = await getIPLocation();
      const formattedAddress = formatAddress(ipLocation);
      setCurrentLocation(formattedAddress);
      onLocationSelect({
        ...ipLocation,
        formatted: formattedAddress
      });

      toast.success('Location detected successfully');

    } catch (error) {
      console.error('Location detection error:', error);
      setError('Could not detect location. Please try again.');
      toast.error('Location detection failed');
    } finally {
      setLoading(false);
    }
  };

  const formatAddress = (locationData) => {
    const components = [];

    if (locationData.area) components.push(locationData.area);
    if (locationData.district) components.push(locationData.district);
    if (locationData.city) components.push(locationData.city);
    if (locationData.state) components.push(locationData.state);
    if (locationData.postal) components.push(locationData.postal);

    return components.filter(Boolean).join(', ');
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <div className="relative w-full">
      <div className="relative flex items-center">
        <input
          type="text"
          className="w-full p-3 pr-12 border rounded-lg focus:ring-2 focus:ring-[rgb(252,123,3)] focus:border-[rgb(252,123,3)] bg-white"
          placeholder={loading ? "Detecting location..." : "Your location"}
          value={currentLocation}
          readOnly
        />
        <button
          onClick={getLocation}
          className="absolute right-3 p-1 text-gray-500 hover:text-[rgb(252,123,3)] transition-colors disabled:opacity-50"
          title="Detect location"
          disabled={loading}
        >
          {loading ? (
            <Loader className="w-5 h-5 animate-spin" />
          ) : (
            <MapPin className="w-5 h-5" />
          )}
        </button>
      </div>
      {error && (
        <p className="mt-1 text-sm text-red-500">{error}</p>
      )}
    </div>
  );
};

export default LocationDetector;