import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  XMarkIcon,
  CheckCircleIcon,
  BanknotesIcon,
  ArrowRightIcon
} from '@heroicons/react/24/solid';
import { toast } from 'react-hot-toast';
import { useAuth } from '../context/AuthContext'; // Assuming you have AuthContext

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api.shata.in',
  withCredentials: true,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default function PaymentPopup({ 
  isOpen, 
  onClose,
  eventDetails,
  amount = 499,
  onLoginClick // New prop to handle login redirect
}) {
  const { isAuthenticated, user } = useAuth(); // Get auth state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (isOpen) {
      loadRazorpay();
    }
    return () => {
      setError(null);
      setLoading(false);
      setSuccess(false);
    };
  }, [isOpen]);

  const loadRazorpay = () => {
    return new Promise((resolve, reject) => {
      if (window.Razorpay) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.onerror = () => reject(new Error('Failed to load Razorpay SDK'));
      document.body.appendChild(script);
      script.onload = () => {
        resolve();
      };
    });
  };

  const handlePayment = async () => {
    try {
      setLoading(true);
      setError(null);

      // Check authentication
      if (!isAuthenticated || !user) {
        throw new Error('Please login to continue');
      }

      const { data } = await api.post('/api/payment/create-order', {
        amount,
        eventDetails: {
          ...eventDetails,
          phoneNumber: user.phoneNumber,
          email: user.email,
          name: user.name
        }
      });

      if (!data?.success || !data?.order?.id) {
        throw new Error('Invalid order response');
      }

      const options = {
        key: data.key,
        amount: data.order.amount,
        currency: data.order.currency || 'INR',
        name: "Shata Events",
        description: "Event Booking Payment",
        order_id: data.order.id,
        prefill: {
          name: user.name || "",
          email: user.email || "",
          contact: user.phoneNumber || ""
        },
        handler: async (response) => {
          try {
            const verifyData = await api.post('/api/payment/verify', {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature
            });

            if (verifyData.data.success) {
              setSuccess(true);
              toast.success('Payment successful!');
            } else {
              throw new Error(verifyData.data.error || 'Payment verification failed');
            }
          } catch (err) {
            console.error('Verification Error:', err);
            setError('Payment verification failed. Please contact support if amount was deducted.');
            toast.error('Payment verification failed');
          }
        },
        modal: {
          ondismiss: () => {
            setLoading(false);
          }
        },
        theme: {
          color: "#f97316"
        }
      };

      const razorpay = new window.Razorpay(options);
      razorpay.on('payment.failed', (response) => {
        setError(`Payment failed: ${response.error.description}`);
        toast.error('Payment failed');
      });
      razorpay.open();

    } catch (err) {
      console.error('Payment Error:', err);
      setError(err.message || 'Payment failed. Please try again.');
      toast.error(err.message || 'Payment failed');
    } finally {
      setLoading(false);
    }
  };

  const renderSuccess = () => (
    <div className="text-center">
      <div className="w-20 h-20 mx-auto bg-green-100 rounded-full flex items-center justify-center mb-4">
        <CheckCircleIcon className="w-12 h-12 text-green-500" />
      </div>
      <h3 className="text-xl font-semibold mb-2">Booking Confirmed!</h3>
      <p className="text-gray-600 mb-6">Your payment was successful</p>
      <button
        onClick={onClose}
        className="w-full py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
      >
        Done
      </button>
    </div>
  );

  const renderLoginButton = () => (
    <button
      onClick={() => {
        onClose();
        onLoginClick();
      }}
      className="w-full py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors flex items-center justify-center gap-2"
    >
      <span>Login to Continue</span>
      <ArrowRightIcon className="w-5 h-5" />
    </button>
  );

  const renderPaymentButton = () => (
    <button
      onClick={handlePayment}
      disabled={loading}
      className="w-full py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors disabled:opacity-50"
    >
      {loading ? 'Processing...' : 'Pay Now'}
    </button>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
        >
          <motion.div
            initial={{ scale: 0.95 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.95 }}
            className="bg-white rounded-xl p-6 max-w-md w-full relative"
          >
            <button
              onClick={() => {
                setError(null);
                onClose();
              }}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>

            {success ? (
              renderSuccess()
            ) : (
              <div>
                <div className="text-center mb-6">
                  <div className="w-16 h-16 mx-auto bg-orange-100 rounded-full flex items-center justify-center mb-4">
                    <BanknotesIcon className="w-8 h-8 text-orange-500" />
                  </div>
                  <h3 className="text-xl font-semibold">Complete Payment</h3>
                  <p className="text-gray-600 mt-1">Amount: ₹{amount}</p>
                </div>

                {error && (
                  <div className="mb-4 p-3 bg-red-50 text-red-500 rounded-lg text-sm">
                    {error}
                  </div>
                )}

                {isAuthenticated ? renderPaymentButton() : renderLoginButton()}
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}