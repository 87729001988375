// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import { toast } from 'react-hot-toast';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
        setLoading(false);
        return;
      }

      const userData = await authService.verifyToken();
      setUser(userData);
    } catch (err) {
      console.error('Auth check failed:', err);
      localStorage.removeItem('auth_token');
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const login = async (credentials) => {
    try {
      setError(null);
      const response = await authService.login(credentials);
      localStorage.setItem('auth_token', response.token);
      setUser(response.user);
      toast.success('Logged in successfully!');
      return response;
    } catch (err) {
      const errorMessage = err.error || 'Login failed. Please try again.';
      toast.error(errorMessage);
      setError(errorMessage);
      throw err;
    }
  };

  const signup = async (userData) => {
    try {
      setError(null);
      const response = await authService.verifySignup(userData);
      localStorage.setItem('auth_token', response.token);
      setUser(response.user);
      toast.success('Account created successfully!');
      return response;
    } catch (err) {
      const errorMessage = err.error || 'Signup failed. Please try again.';
      toast.error(errorMessage);
      setError(errorMessage);
      throw err;
    }
  };

  const sendOTP = async (phoneNumber) => {
    try {
      setError(null);
      const response = await authService.sendOTP(phoneNumber);
      toast.success('OTP sent successfully!');
      return response;
    } catch (err) {
      const errorMessage = err.error || 'Failed to send OTP. Please try again.';
      toast.error(errorMessage);
      setError(errorMessage);
      throw err;
    }
  };

  const logout = () => {
    localStorage.removeItem('auth_token');
    setUser(null);
    toast.success('Logged out successfully');
    navigate('/');
  };

  const value = {
    user,
    loading,
    error,
    login,
    signup,
    logout,
    sendOTP,
    isAuthenticated: !!user,
  };

  if (loading) {
    return <div className="h-screen w-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-orange-500"></div>
    </div>;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;