import React, { useState, useEffect } from 'react';
import { Calendar as CalendarIcon } from 'lucide-react';

const CustomDatePicker = ({ value, onChange, error }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(getIndianDate());

  // Function to get current date in Indian timezone
  function getIndianDate() {
    const now = new Date();
    const indiaTime = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
    indiaTime.setHours(0, 0, 0, 0);
    return indiaTime;
  }

  // Today in Indian timezone
  const today = getIndianDate();

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    return {
      daysInMonth: lastDay.getDate(),
      startingDay: firstDay.getDay()
    };
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    // Parse the input date string and convert to Indian timezone
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      timeZone: 'Asia/Kolkata',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  const handleDateSelect = (day) => {
    // Create date in Indian timezone
    const selectedDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day,
      0, 0, 0
    );

    // Format as YYYY-MM-DD in Indian timezone
    const dateInIndia = new Date(selectedDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
    const year = dateInIndia.getFullYear();
    const month = String(dateInIndia.getMonth() + 1).padStart(2, '0');
    const dayStr = String(dateInIndia.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${dayStr}`;

    onChange(formattedDate);
    setShowCalendar(false);
  };

  const isWeekend = (day) => {
    const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
    return date.getDay() === 0 || date.getDay() === 6;
  };

  const renderCalendarDays = () => {
    const { daysInMonth, startingDay } = getDaysInMonth(currentMonth);
    const days = [];

    // Add empty cells for days before the first day of the month
    for (let i = 0; i < startingDay; i++) {
      days.push(
        <div 
          key={`empty-${i}`} 
          className="h-10 w-10"
        />
      );
    }

    // Add cells for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const dateToCheck = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        day,
        0, 0, 0
      );

      const isDisabled = dateToCheck < today;
      const selectedDate = value ? new Date(value + 'T00:00:00+05:30') : null;
      const isSelected = selectedDate && 
        dateToCheck.getDate() === selectedDate.getDate() &&
        dateToCheck.getMonth() === selectedDate.getMonth() &&
        dateToCheck.getFullYear() === selectedDate.getFullYear();
      const isToday = dateToCheck.getTime() === today.getTime();
      const weekend = isWeekend(day);

      days.push(
        <button
          key={day}
          disabled={isDisabled}
          onClick={() => !isDisabled && handleDateSelect(day)}
          className={`
            relative h-10 w-10 rounded-full flex items-center justify-center text-sm
            transition-all duration-200 hover:scale-110
            ${isDisabled ? 'bg-gray-50 text-gray-300 cursor-not-allowed' : 
              isSelected ? 'bg-[rgb(252,123,3)] text-white shadow-lg hover:bg-[rgb(232,103,0)]' :
              isToday ? 'bg-orange-50 text-[rgb(252,123,3)] font-semibold hover:bg-orange-100' :
              weekend ? 'text-[rgb(252,123,3)] hover:bg-orange-50' :
              'text-gray-700 hover:bg-orange-50 hover:text-[rgb(252,123,3)]'}
          `}
        >
          {day}
          {isToday && (
            <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-1 h-1 bg-[rgb(252,123,3)] rounded-full" />
          )}
        </button>
      );
    }

    return days;
  };

  const navigateMonth = (direction) => {
    setCurrentMonth(new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + direction,
      1
    ));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showCalendar && !event.target.closest('.calendar-container')) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showCalendar]);

  return (
    <div className="relative">
      <div
        onClick={() => setShowCalendar(!showCalendar)}
        className={`
          flex items-center justify-between px-4 py-3 border rounded-lg cursor-pointer
          transition-all duration-200
          ${error ? 'border-red-500' : 'border-gray-300'}
          hover:border-[rgb(252,123,3)] hover:shadow-md
        `}
      >
        <span className={`text-base ${value ? 'text-gray-700' : 'text-gray-400'}`}>
          {value ? formatDate(value) : 'Select date'}
        </span>
        <CalendarIcon className="h-6 w-6 text-[rgb(252,123,3)]" />
      </div>

      {error && (
        <p className="mt-1 text-sm text-red-500">{error}</p>
      )}

      {showCalendar && (
        <div className="calendar-container absolute z-50 mt-2 p-4 bg-white rounded-xl shadow-xl border border-gray-100 w-[320px] transform transition-all duration-200">
          {/* Calendar Header */}
          <div className="flex justify-between items-center mb-6">
            <button
              onClick={() => navigateMonth(-1)}
              className="p-2 hover:bg-orange-50 rounded-lg text-[rgb(252,123,3)] transition-colors"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <span className="font-semibold text-gray-700">
              {currentMonth.toLocaleDateString('en-US', { 
                timeZone: 'Asia/Kolkata',
                month: 'long', 
                year: 'numeric' 
              })}
            </span>
            <button
              onClick={() => navigateMonth(1)}
              className="p-2 hover:bg-orange-50 rounded-lg text-[rgb(252,123,3)] transition-colors"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>

          {/* Calendar Grid */}
          <div className="grid grid-cols-7 gap-1 mb-2">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
              <div key={day} className="h-10 w-10 flex items-center justify-center text-sm font-medium text-gray-400">
                {day}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 gap-1">
            {renderCalendarDays()}
          </div>

          <div className="mt-4 pt-4 border-t border-gray-100">
            <div className="flex items-center space-x-4 text-sm">
              <div className="flex items-center space-x-1">
                <div className="w-3 h-3 rounded-full bg-orange-50 border-2 border-[rgb(252,123,3)]" />
                <span className="text-gray-600">Today</span>
              </div>
              <div className="flex items-center space-x-1">
                <div className="w-3 h-3 rounded-full bg-orange-50" />
                <span className="text-gray-600">Available</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;