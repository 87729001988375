import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Calendar, 
  Camera, 
  UtensilsCrossed, 
  PartyPopper, 
  Menu, 
  X, 
  HelpCircle, 
  User,
  Phone as PhoneIcon,
  Minus,
  Plus,
  Copy,
  Check
} from 'lucide-react';
import { toast, Toaster } from 'react-hot-toast';
import CheesyEventLines from './CheesyEventLines';
import PaymentPopup from './components/PaymentPopup';
import LoginModal from './components/LoginModal';
import { AccountMenu } from './components/AccountMenu';
import CitySearch from './CitySearch';
import LanguageSelector from './LanguageSelector';
import { useAuth } from './context/AuthContext';
import CustomDatePicker from './components/CustomDatePicker';
import LocationDetector from './LocationDetector';

const CustomStyles = () => (
  <style>{`
    @keyframes fade-slide-up {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .animate-fade-slide-up {
      animation: fade-slide-up 0.5s ease-out;
    }
  `}</style>
);

const NavItems = ({ activeItem, setActiveItem }) => {
  const navItems = [
    { name: 'Events', icon: PartyPopper },
    { name: 'Photography', icon: Camera },
    { name: 'Catering', icon: UtensilsCrossed },
  ];

  return (
    <>
      {navItems.map(({ name, icon: Icon }) => (
        <button
          key={name}
          onClick={() => setActiveItem(name)}
          className={`flex items-center space-x-3 px-6 py-3 rounded-lg text-lg transition-all duration-300 transform hover:scale-105 active:scale-95 ${
            activeItem === name
              ? 'bg-[rgb(252,123,3)] text-white'
              : 'bg-white text-black hover:bg-gray-100'
          }`}
        >
          <Icon size={24} className={activeItem === name ? 'text-white' : 'text-black'} />
          <span className="font-medium">{name}</span>
        </button>
      ))}
    </>
  );
};

const OfferCard = ({ offer, index }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(offer.tag);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);

    toast.success(`Copied code: ${offer.tag}`, {
      position: 'top-right',
      duration: 2000,
    });
  };

  return (
    <div 
      className={`bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl 
        transition-all duration-300 transform hover:scale-105 opacity-0 animate-fade-slide-up`}
      style={{ 
        animationDelay: `${index * 150}ms`,
        animationFillMode: 'forwards'
      }}
    >
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <span className="text-4xl">{offer.icon}</span>
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium px-3 py-1 bg-orange-100 text-orange-500 rounded-full">
              {offer.tag}
            </span>
            <button
              onClick={handleCopy}
              className="text-gray-500 hover:text-orange-500 transition-colors transform hover:scale-110 active:scale-90"
              aria-label={copied ? "Copied!" : "Copy code"}
            >
              {copied ? <Check size={18} /> : <Copy size={18} />}
            </button>
          </div>
        </div>
        <h3 className="text-lg font-medium mb-2">{offer.title}</h3>
        <p className="text-sm text-gray-500">{offer.validity}</p>
      </div>
    </div>
  );
};

const Header = ({ isMenuOpen, setIsMenuOpen, activeItem, setActiveItem, onLoginClick }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  return (
    <header className="border-b shadow-sm bg-white sticky top-0 z-50 animate-slideDown">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex items-center justify-between">
          {/* Logo and Desktop Navigation */}
          <div className="flex items-center space-x-12">
            <div className="h-12 w-40 transform hover:scale-105 transition-transform">
              <img src="/shatalogo.png" alt="Shata Logo" className="h-full w-full object-contain" />
            </div>
            <nav className="hidden lg:flex space-x-8">
              <NavItems activeItem={activeItem} setActiveItem={setActiveItem} />
            </nav>
          </div>

          {/* Desktop Menu Items */}
          <div className="hidden lg:flex items-center space-x-8">
            <LanguageSelector />
            <a 
              href="mailto:info@theshata.com"
              className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:text-[rgb(252,123,3)] text-lg transform hover:scale-105 transition-all"
            >
              <HelpCircle size={24} />
              <span>Help</span>
            </a>
            <AccountMenu onLoginClick={onLoginClick} />
          </div>

          {/* Mobile Menu Button */}
          <button 
            className="lg:hidden p-2 hover:bg-gray-100 rounded-lg transform hover:scale-110 active:scale-95 transition-all"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X size={28} /> : <Menu size={28} />}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="lg:hidden pt-4 pb-3 space-y-4 animate-slideDown">
            <nav className="flex flex-col space-y-2">
              <NavItems activeItem={activeItem} setActiveItem={setActiveItem} />
              <LanguageSelector />
              <a 
                href="mailto:info@theshata.com"
                className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:text-[rgb(252,123,3)] text-lg transform hover:scale-105 transition-all"
              >
                <HelpCircle size={24} />
                <span>Help</span>
              </a>
              
              {/* Mobile Account Options */}
              <div className="border-t pt-2 space-y-2">
                {!isAuthenticated && (
                  <button
                    onClick={() => {
                      setIsMenuOpen(false);
                      onLoginClick();
                    }}
                    className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:text-[rgb(252,123,3)] text-lg transform hover:scale-105 transition-all w-full"
                  >
                    <User size={24} />
                    <span>Login/Signup</span>
                  </button>
                )}
                <button
                  onClick={() => {
                    setIsMenuOpen(false);
                    navigate('/my-bookings');
                  }}
                  className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:text-[rgb(252,123,3)] text-lg transform hover:scale-105 transition-all w-full"
                >
                  <Calendar size={24} />
                  <span>My Bookings</span>
                </button>
              </div>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

const EventBookingPlatform = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('Events');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState('');
  const [location, setLocation] = useState('');
  const [budget, setBudget] = useState('');
  const [date, setDate] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [openFaq, setOpenFaq] = useState(null);
  const [errors, setErrors] = useState({});
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { isAuthenticated } = useAuth();
  const [detectedLocation, setDetectedLocation] = useState(null);

  const handleLocationSelect = (locationData) => {
    const formattedLocation = `${locationData.city},${locationData.country}`;
    setLocation(formattedLocation);
    setSelectedLocation(locationData);

    // Clear the location error if it exists
    if (errors.location) {
      setErrors(prev => ({ ...prev, location: undefined }));
    }
  };


  const eventTypes = [
    'Birthdays',
    'Surprises',
    'Family Functions',
    'Proposal',
    'Weddings',
    'Engagement',
    'Corporate Events',
    'Pre-release',
  ];

  const shootTypes = [
    'Maternity Shoot',
    'Pre-Wedding Shoot',
    'Wedding Shoot', 
    'Post-Wedding Shoot',
    'Baby Shower Shoot',
    'Self Portraits',
    'Professional Portraits',
  ];

  const cateringTypes = [
    'Wedding Buffet',
    'Plated Meal',
    'Food Stalls',
    'Cocktail Reception',
    'Live Counters'
  ];

  const trendingOffers = [
    {
      icon: '🎉',
      title: 'Save up to ₹5000 on Birthday packages',
      tag: 'EVENTS',
      validity: 'Valid till 30 Nov',
    },
    {
      icon: '🍽️',
      title: 'Save up to ₹3000 on Catering services',
      tag: 'CATERING',
      validity: 'Valid till 30 Nov',
    },
    {
      icon: '📸',
      title: 'Flat 25% off on Photography packages',
      tag: 'PHOTO',
      validity: 'Valid till 30 Nov',
    },
    {
      icon: '💝',
      title: 'Special discount on Surprise events',
      tag: 'EVENTS',
      validity: 'Valid till 30 Nov',
    },
  ];

  const faqs = [
    {
      question: "How does Shata's event booking process work?",
      answer: "Our booking process is simple and seamless. First, select your event type and location. Then, specify your budget and preferred date. Our platform will match you with the best vendors and venues that fit your criteria. Once you've made your selection, you can customize your package and confirm your booking with a secure payment."
    },
    {
      question: "What happens if I need to reschedule my event?",
      answer: "We understand that plans can change. You can reschedule your event up to 15 days before the scheduled date without any penalty. For changes within 15 days, a small rescheduling fee may apply. Simply contact our 24/7 support team, and we'll help you find a new date that works for you and your vendors."
    },
    {
      question: "Are there any hidden charges in the packages?",
      answer: "No, we believe in complete transparency. The price you see is the price you pay. All applicable taxes, service charges, and vendor fees are clearly displayed before you confirm your booking. If there are any additional optional services available, they will be clearly marked as such."
    },
    {
      question: "Can I customize my event package?",
      answer: "Absolutely! While we offer pre-designed packages for convenience, every aspect can be customized to match your vision. From decor themes to catering menus, photography styles to entertainment options - you have full control over your event details. Our event specialists can help you create the perfect custom package."
    },
    {
      question: "What is your cancellation policy?",
      answer: "Our cancellation policy is designed to be fair to both customers and vendors. Cancellations made 30 days or more before the event date are eligible for a full refund minus processing fees. For cancellations between 15-29 days, 70% refund is provided. Cancellations within 14 days are subject to our detailed policy terms, which vary by event type."
    }
  ];

  useEffect(() => {
    const img = new Image();
    img.src = '/bgimage.jpg';
    img.onload = () => setImageLoaded(true);
  }, []);

  const getDynamicTypes = () => {
    switch(activeItem) {
      case 'Events': return eventTypes;
      case 'Photography': return shootTypes;
      case 'Catering': return cateringTypes;
      default: return [];
    }
  };

  const getBudgetOptions = (selectedType, activeSection) => {
    if (activeSection === 'Events') {
      switch(selectedType) {
        case 'Birthdays':
          return ['5,000 to 25,000', '25,000 to 50,000', '50,000 to 1,00,000', 'Customizable'];
        case 'Surprises':
          return ['50,000 to 1,00,000', '1,00,000 to 5,00,000', '5,00,000 to 15,00,000', 'Customizable'];
        case 'Family Functions':
          return ['1,00,000 to 5,00,000', '5,00,000 to 15,00,000', '15,00,000 to 25,00,000', 'Customizable'];
        case 'Proposal':
          return ['10,000 to 50,000', '50,000 to 1,00,000', '1,00,000 to 5,00,000', 'Customizable'];
        case 'Weddings':
          return ['10,00,000 to 25,00,000', '25,00,000 to 50,00,000', '50,00,000 to 1,00,00,000', 'Customizable'];
        case 'Engagement':
          return ['5,00,000 to 10,00,000', '10,00,000 to 25,00,000', '25,00,000 to 50,00,000', 'Customizable'];
        case 'Corporate Events':
          return ['25,000 to 50,000', '50,000 to 1,00,000', '1,00,000 to 5,00,000', 'Customizable'];
        case 'Pre-release':
          return ['5,00,000 to 10,00,000', '10,00,000 to 25,00,000', '25,00,000 to 50,00,000', '50,00,000 to 1,00,00,000', 'Customizable'];
        default:
          return ['Select Budget'];
      }
    } else if (activeSection === 'Photography') {
      switch(selectedType) {
        case 'Self Portraits':
          return ['5,000 to 15,000', '15,000 to 30,000', '30,000 to 50,000', 'Customizable'];
        case 'Maternity Shoot':
          return ['25,000 to 50,000', '50,000 to 1,00,000', '1,00,000 to 2,00,000', 'Customizable'];
        case 'Pre-Wedding Shoot':
          return ['50,000 to 1,00,000', '1,00,000 to 2,50,000', '2,50,000 to 5,00,000', 'Customizable'];
        case 'Wedding Shoot':
          return ['2,00,000 to 5,00,000', '5,00,000 to 10,00,000', '10,00,000 to 20,00,000', 'Customizable'];
        case 'Post-Wedding Shoot':
          return ['50,000 to 1,50,000', '1,50,000 to 3,00,000', '3,00,000 to 5,00,000', 'Customizable'];
        case 'Baby Shower Shoot':
          return ['15,000 to 35,000', '35,000 to 75,000', '75,000 to 1,50,000', 'Customizable'];
        case 'Professional Portraits':
          return ['10,000 to 25,000', '25,000 to 50,000', '50,000 to 1,00,000', 'Customizable'];
        default:
          return ['Select Budget'];
      }
    } else if (activeSection === 'Catering') {
      switch(selectedType) {
        case 'Wedding Buffet':
          return ['5,00,000 to 10,00,000', '10,00,000 to 25,00,000', '25,00,000 to 50,00,000', 'Customizable'];
        case 'Plated Meal':
          return ['1,00,000 to 3,00,000', '3,00,000 to 7,00,000', '7,00,000 to 15,00,000', 'Customizable'];
        case 'Food Stalls':
          return ['50,000 to 2,00,000', '2,00,000 to 5,00,000', '5,00,000 to 10,00,000', 'Customizable'];
        case 'Cocktail Reception':
          return ['1,50,000 to 4,00,000', '4,00,000 to 8,00,000', '8,00,000 to 15,00,000', 'Customizable'];
        case 'Live Counters':
          return ['75,000 to 2,50,000', '2,50,000 to 5,00,000', '5,00,000 to 10,00,000', 'Customizable'];
        default:
          return ['Select Budget'];
      }
    }
    return ['Select Budget'];
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleServiceClick = (service) => {
    setActiveItem(service);
    scrollToTop();
  };
  const validateForm = () => {
    const newErrors = {};
    
    if (!selectedEventType) {
      newErrors.eventType = `Please select a ${
        activeItem === 'Events' ? 'Event Type' : 
        activeItem === 'Photography' ? 'Shoot Type' : 
        'Catering Type'
      }`;
    }
    
    if (!budget) {
      newErrors.budget = 'Please select a budget range';
    }
    
    if (!selectedLocation) {
      newErrors.location = 'Please select a city';
    }
    
    if (!date) {
      newErrors.date = 'Please select a date';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCitySelect = (city) => {
    const formattedLocation = `${city.city},${city.country}`;
    setLocation(formattedLocation);
    setSelectedLocation(city);
    if (errors.location) {
      setErrors(prev => ({ ...prev, location: undefined }));
    }
  };

  const handleConfirmBooking = () => {
    if (!validateForm()) {
      return;
    }

    const allowedLocations = ['Hyderabad,India', 'Guntur,India'];
    
    if (!allowedLocations.includes(location)) {
      toast.error('Booking is currently available only in Hyderabad and Guntur', {
        position: 'top-right',
        duration: 3000,
      });
      return;
    }
  
    setIsPaymentPopupOpen(true);
  };

  return (
    <div className="min-h-screen bg-white">
       <CustomStyles />
      <Toaster />
      
      <Header 
        isMenuOpen={isMenuOpen} 
        setIsMenuOpen={setIsMenuOpen} 
        activeItem={activeItem} 
        setActiveItem={setActiveItem}
        onLoginClick={() => setShowLoginModal(true)}
      />

      {/* Hero Section */}
      <div
        className={`relative min-h-[600px] bg-cover bg-center transition-opacity duration-1000 ${
          imageLoaded ? 'opacity-100' : 'opacity-0'
        }`}
        style={{
          backgroundImage: `linear-gradient(rgba(252, 123, 3, 0.7), rgba(252, 123, 3, 0.8)),url('/bgimage.jpg')`,
          backgroundAttachment: 'fixed',
        }}
      >
        <div className="absolute inset-0 bg-black/20" />

        <div className="relative z-10 max-w-7xl mx-auto px-4 py-16">
          <h1 className="text-white text-3xl md:text-5xl font-bold text-center mb-12">
            {activeItem === 'Events' 
              ? "India's First Event Booking Site" 
              : activeItem === 'Photography'
              ? "Professional Photography Services"
              : "Serving Flavors – Your Perfect Catering Partner!"}
          </h1>

          {/* Search Form */}
          <div className="bg-white/95 backdrop-blur-sm rounded-lg shadow-xl p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {/* Event Type Selection */}
            <div className="relative md:col-span-2 lg:col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {activeItem === 'Events' ? 'Event Type' : 
                 activeItem === 'Photography' ? 'Shoot Type' : 
                 'Catering Type'}
                <span className="text-red-500 ml-1">*</span>
              </label>
              <select
                className={`w-full p-3 border rounded-lg appearance-none focus:border-[rgb(252,123,3)] focus:ring-1 focus:ring-[rgb(252,123,3)] ${
                  errors.eventType ? 'border-red-500' : ''
                }`}
                value={selectedEventType}
                onChange={(e) => {
                  setSelectedEventType(e.target.value);
                  setBudget('');
                  if (errors.eventType) {
                    setErrors(prev => ({ ...prev, eventType: undefined }));
                  }
                }}
              >
                <option value="">Select Type</option>
                {getDynamicTypes().map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
              {errors.eventType && (
                <p className="text-red-500 text-xs mt-1">{errors.eventType}</p>
              )}
            </div>

            {/* Budget Selection */}
            {selectedEventType && (
              <div className="relative md:col-span-2 lg:col-span-1">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Budget
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <select
                  className={`w-full p-3 border rounded-lg appearance-none focus:border-[rgb(252,123,3)] focus:ring-1 focus:ring-[rgb(252,123,3)] ${
                    errors.budget ? 'border-red-500' : ''
                  }`}
                  value={budget}
                  onChange={(e) => {
                    setBudget(e.target.value);
                    if (errors.budget) {
                      setErrors(prev => ({ ...prev, budget: undefined }));
                    }
                  }}
                >
                  <option value="">Select Budget Range</option>
                  {getBudgetOptions(selectedEventType, activeItem).map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                {errors.budget && (
                  <p className="text-red-500 text-xs mt-1">{errors.budget}</p>
                )}
              </div>
            )}

            {/* Location Selection */}
            <div className="relative md:col-span-2 lg:col-span-1 z-50">
  <label className="block text-sm font-medium text-gray-700 mb-2">
    Location
    <span className="text-red-500 ml-1">*</span>
  </label>
  <div className="relative space-y-2">

    <LocationDetector 
      onLocationSelect={(locationInfo) => {
        if (locationInfo.city && locationInfo.country) {
          handleCitySelect({
            city: locationInfo.city,
            country: locationInfo.country
          });
          if (errors.location) {
            setErrors(prev => ({ ...prev, location: undefined }));
          }
        }
      }}
    />
    {errors.location && (
      <p className="text-red-500 text-xs mt-1">{errors.location}</p>
    )}
  </div>
</div>

            {/* Date Selection */}
           {/* Date Selection */}
<div className="relative md:col-span-2 lg:col-span-1">
  <label className="block text-sm font-medium text-gray-700 mb-2">
    Date
    <span className="text-red-500 ml-1">*</span>
  </label>
  <CustomDatePicker
    value={date}
    onChange={(newDate) => {
      setDate(newDate);
      if (errors.date) {
        setErrors(prev => ({ ...prev, date: undefined }));
      }
    }}
    error={errors.date}
  />
</div>

            {/* Confirm Button */}
            <div className="col-span-full">
              <button 
                onClick={handleConfirmBooking}
                className="mt-6 w-full py-4 rounded-lg font-medium transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg relative overflow-hidden group z-40"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-[#fc7b03] via-[#ff9234] to-[#fc7b03] group-hover:bg-gradient-to-r group-hover:from-[#ff9234] group-hover:via-[#fc7b03] group-hover:to-[#ff9234] transition-all duration-300"></div>
                <span className="relative text-white text-lg">Confirm</span>
              </button>
            </div>
          </div>

          <CheesyEventLines />
        </div>
      </div>

      {/* Trending Offers */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-bold">TRENDING OFFERS</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {trendingOffers.map((offer, index) => (
            <OfferCard key={index} offer={offer} index={index} />
          ))}
        </div>
      </div>

      {/* Significance Section */}
      <div className="max-w-7xl mx-auto px-4 py-16 bg-gray-50">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-4xl font-bold text-[rgb(252,123,3)] mb-6">
              Significance of SHATA
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Event booking in India has become increasingly significant due to the country's burgeoning
              event industry. With a diverse population and a rising middle class, there is a growing demand
              for a wide range of events, from birthdays, weddings and corporate conferences to music
              festivals and cultural celebrations.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              Shata offers a convenient and efficient way to manage and organize events, attracting a large
              market of event planners, organizers, and attendees. As the Indian market continues to expand,
              the role of shata in streamlining processes, optimizing resources, and enhancing the overall
              event experience is set to grow even further.
            </p>
          </div>
          <div className="flex justify-center">
            <div className="rounded-lg overflow-hidden shadow-xl w-full">
              <img 
                src="/significance.jpg" 
                alt="Event Setup"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>

     {/* Coming Soon Section */}
<div className="bg-[rgb(252,123,3)]">
  <div className="max-w-7xl mx-auto px-4 py-16">
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
      <div className="space-y-6">
        <div className="inline-block bg-white rounded-lg px-6 py-3">
          <h3 className="text-[rgb(252,123,3)] text-2xl font-bold">COMING SOON</h3>
        </div>
        <h2 className="text-4xl font-bold text-white italic">
          Android and IOS Applications are on the way!
        </h2>
      </div>
      <div className="flex justify-center lg:justify-end">
        <img 
          src="/WEB.png"
          alt="Mobile apps coming soon illustration"
          className="max-w-md w-full object-contain"
        />
      </div>
    </div>
  </div>
</div>

{/* HJ Image Section */}
<div className="px-4 md:px-8 lg:px-16 py-8 md:py-16 lg:py-24">
  <div className="rounded-xl md:rounded-3xl overflow-hidden max-w-[1200px] mx-auto">
    <img 
      src="/web22.png" 
      alt="HJ Image" 
      className="w-full h-auto object-cover"
    />
  </div>
</div>
      {/* FAQ Section */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Frequently Asked Questions</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Get answers to common questions about our event booking platform. Can't find what you're looking for? Contact our support team.
          </p>
        </div>

        <div className="max-w-3xl mx-auto space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white rounded-xl overflow-hidden transition-all duration-300 hover:shadow-lg"
            >
              <button
                className="w-full text-left p-6 focus:outline-none focus:ring-2 focus:ring-[rgb(252,123,3)] focus:ring-opacity-50 relative"
                onClick={() => setOpenFaq(openFaq === index ? null : index)}
              >
                <div className="flex items-center justify-between">
                  <h3 className="font-medium text-lg pr-8">{faq.question}</h3>
                  <div 
                    className={`text-[rgb(252,123,3)] transition-transform duration-300 ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  >
                    {openFaq === index ? (
                      <Minus className="w-5 h-5" />
                    ) : (
                      <Plus className="w-5 h-5" />
                    )}
                  </div>
                </div>
              </button>
              
              <div 
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  openFaq === index ? 'max-h-96' : 'max-h-0'
                }`}
              >
                <div className="p-6 pt-0 text-gray-600 border-t">
                  {faq.answer}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Contact Support Call-to-Action */}
        <div className="mt-12 text-center">
          <p className="text-gray-600 mb-4">Still have questions?</p>
          <button 
            onClick={() => window.location.href = 'tel:+919701811134'} 
            className="inline-flex items-center gap-2 px-6 py-3 bg-orange-500 text-white font-medium rounded-lg hover:bg-orange-600 transition-colors duration-300"
          >
            <PhoneIcon className="w-5 h-5" />
            Contact Support
          </button>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-50 px-4 py-12 mt-auto">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row md:justify-between gap-8">
            {/* Company Info */}
            <div className="md:w-1/3">
              <div className="mb-4">
                <img
                  src="/shatalogo.png"
                  alt="Company Logo"
                  className="h-12"
                />
              </div>
              <p className="text-gray-600 text-sm">
                Shata is India's First online event booking service. Shata offers event booking through its website, iOS and Android mobile apps.
              </p>
            </div>

            {/* Links Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:w-2/3">
              {/* About */}
              <div>
                <h3 className="font-semibold text-lg mb-4">About Shata</h3>
                <ul className="space-y-2">
                  <li><a href="#" className="text-gray-600 hover:text-red-500 text-sm">Contact us</a></li>
                  <li><a href="#" className="text-gray-600 hover:text-red-500 text-sm">Mobile version</a></li>

                </ul>
              </div>

              {/* Info Section */}
              <div>
                <h3 className="font-semibold text-lg mb-4">Info</h3>
                <ul className="space-y-2">
                  <li><a href="/termsandconditions.html" className="text-gray-600 hover:text-red-500 text-sm">T&C</a></li>
                  <li><a href="/legalpage.html" className="text-gray-600 hover:text-red-500 text-sm">Privacy Policy</a></li>
                </ul>
              </div>

              {/* Services */}
              <div>
                <h3 className="font-semibold text-lg mb-4">Services</h3>
                <ul className="space-y-2">
                  <li>
                    <button 
                      onClick={() => handleServiceClick('Events')}
                      className="text-gray-600 hover:text-red-500 text-sm text-left"
                    >
                      Events
                    </button>
                  </li>
                  <li>
                    <button 
                      onClick={() => handleServiceClick('Photography')}
                      className="text-gray-600 hover:text-red-500 text-sm text-left"
                    >
                      Photography
                    </button>
                  </li>
                  <li>
                    <button 
                      onClick={() => handleServiceClick('Catering')}
                      className="text-gray-600 hover:text-red-500 text-sm text-left"
                    >
                      Catering
                    </button>
                  </li>
                </ul>
              </div>
            
            </div>
          </div>

          {/* Copyright and Social Media */}
          <div className="mt-12 pt-8 border-t border-gray-200 flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-600 text-sm mb-4 md:mb-0">
              © 2024 Shata Pvt Ltd. All rights reserved
            </p>
            <div className="flex space-x-6">
              <a href="https://www.facebook.com/masthmasala?mibextid=LQQJ4d&rdid=FXmP2Q3TDZfFzfKz&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fju64Pjjz9rok8efU%2F%3Fmibextid%3DLQQJ4d#" className="text-gray-600 hover:text-red-500">
                <i className="fab fa-facebook fa-lg"></i>
              </a>
              <a href="https://www.linkedin.com/company/shata/" className="text-gray-600 hover:text-red-500">
                <i className="fab fa-linkedin fa-lg"></i>
              </a>
              <a href="https://x.com/AppShata?t=cH_73mfHFijPtVNGsNDfOA&s=08" className="text-gray-600 hover:text-red-500">
                <i className="fab fa-twitter fa-lg"></i>
              </a>
              <a href="https://www.instagram.com/shata_app/profilecard/?igsh=MWhiNDVreGRicTM2cA==" className="text-gray-600 hover:text-red-500">
                <i className="fab fa-instagram fa-lg"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>

      {/* Modals */}
      <LoginModal 
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
      />

      <PaymentPopup 
        isOpen={isPaymentPopupOpen}
        onClose={() => setIsPaymentPopupOpen(false)}
        eventDetails={{
          eventType: selectedEventType,
          date,
          location: selectedLocation,
          budget
        }}
        onLoginClick={() => {
          setIsPaymentPopupOpen(false);
          setShowLoginModal(true);
        }}
      />
    </div>
  );
};

const styles = `
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .animate-slideDown {
    animation: slideDown 0.3s ease-out forwards;
  }
`;

export default EventBookingPlatform;